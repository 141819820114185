<template>
  <div style="padding: 2em;">
    <div class="row">
      <div class="col-lg-8 col-lg-offset-2">
        <!-- <h2>Contact form</h2> -->
        <div style="width: fit-content" class="mb-4">
          <h2>Contact form</h2>
          <div
            style="width: auto; background-color: #e31b1b; height: 0.17em"
          ></div>
        </div>

        <div v-if="successSend" class="alert alert-success alert-dismissable">
          Your message was sent, thank you. We will get back to you soon.
        </div>

        <form @submit.prevent="send" id="contact-form" role="form">
          <div class="controls">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="user-name">Name *</label>
                  <b-form-input
                    v-model="name"
                    :state="validState.name"
                    id="user-name"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="validState.name">
                    Name is required
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="form_lastname">Business group</label>
                  <b-form-select
                    class="form-control"
                    v-model="business_group"
                    disabled
                    :options="[
                      { text: 'Harms Automotive', value: 'Harms Automotive' },
                    ]"
                  ></b-form-select>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="user-email">Email *</label>
                  <b-form-input
                    v-model="contact_email"
                    :state="validState.contact_email"
                    id="user-email"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="validState.contact_email">
                    Email is invalid
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="store">Store*</label>
                  <b-form-input
                    class="form-control"
                    v-model="location"
                    :state="validState.location"
                    id="store"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="validState.location">
                    Store is required
                  </b-form-invalid-feedback>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="form_message">Message *</label>
                  <b-form-textarea
                    id="textarea"
                    v-model="message"
                    placeholder="Enter message..."
                    rows="3"
                    max-rows="6"
                    :state="validState.message"
                  ></b-form-textarea>
                  <b-form-invalid-feedback :state="validState.message">
                    Message is required
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="col-md-12 mt-2 mb-3 d-flex justify-content-center">
                <button
                  class="btn btn-primary btn-lg"
                  style="width: 170px"
                  type="submit"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      business_group: "Harms Automotive",
      location: "",
      name: "",
      contact_email: "",
      message: "",

      validState: {
        location: null,
        name: null,
        contact_email: null,
        message: null,
      },
      successSend: false,
    };
  },

  methods: {
    resetValidStateForm() {
      this.validState = {
        location: null,
        name: null,
        contact_email: null,
        message: null,
      };
    },
    isValidForm() {
      this.validState = {
        location: this.location.length > 0,
        contact_email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(
          this.contact_email
        ),
        name: this.name.length > 0,
        message: this.message.length > 0,
      };

      return Object.keys(this.validState).every((k) => this.validState[k]);
    },

    sendToAPI() {
      fetch(
        "https://us-central1-car-appointment-fsqahl.cloudfunctions.net/appticketFwd",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorize: "f3CIZUdYwzlj64vydOaci7NTPy0wZf4V",
          },
          body: JSON.stringify({
            business_group: this.business_group,
            location: this.location,
            name: this.name,
            contact_email: this.contact_email,
            message: this.message,
          }),
        }
      );
    },

    send() {
      if (this.isValidForm()) {
        this.sendToAPI();
        this.successSend = true;
        setTimeout(() => {
          this.successSend = false;
        }, 1000 * 10);
      } else {
        setTimeout(() => {
          this.resetValidStateForm();
        }, 1000 * 5);
      }
    },
  },
};
</script>
