<template>
  <div id="app">
    <Overview/>
    <ContactForm/>
  </div>
</template>

<script>
import Overview from './components/Overview.vue';
import ContactForm from './components/ContactForm.vue';

export default {
  name: 'App',
  components: {
    Overview,
    ContactForm
  }
}
</script>
